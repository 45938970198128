<template>
  <div>
    <AppSubHeader
      link='plan'
      title='Mi Plan'
    />
    <main class="m-content container mb-5">
      <div class="row">
        <div class="col-12 mx-auto">
          <div class="m-portlet m-portlet--full-height ">
            <div class="m-portlet__head">
              <div class="m-portlet__head-caption">
                <div class="m-portlet__head-title">
                  <h3 class="m-portlet__head-text">
                    <strong>Qnt</strong> - PLAN {{plans.plan}}
                  </h3>
                </div>
              </div>
            </div>
            <div class="m-portlet__body">
              <div class="m-widget3">
                <div class="m-widget3__item">
                  <div class="row">
                    <div class="col-sm-12 col-md-4 text-center">
                      <div>
                        Valor de la cuota
                      </div>
                      <div>
                        <h3>
                          <strong class="fonFamilyBarlow">
                            {{ plans.cuota  | currency}}
                          </strong>
                        </h3>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-4 text-center">
                      <div>
                        Número de cuotas
                      </div>
                      <div>
                        <h3>
                          <strong class="fonFamilyBarlow">
                            {{ plans.plazo }}
                          </strong>
                        </h3>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-4 text-center">
                      <div>
                        Tasa de interés EA
                      </div>
                      <div>
                        <h3>
                          <strong class="fonFamilyBarlow">
                            {{ plans.tasa_ea }} %
                          </strong>
                        </h3>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12 text-center fonFamilyBarlow font-weight-light py-2">
                      <span class="font18">
                        Su plan incluye:
                      </span>
                    </div>
                    <div class="col-12 text-center py-2">
                      <div
                        class="py-1"
                        v-if="plans.plan=='ACERTADO'"
                      >
                        <b>Oferta tarjeta crédito hasta</b><br />
                        (Valor por definir)
                      </div>
                    </div>
                    <div class="col-12 text-center py-2">
                      <div class="py-1">
                        <b>Día de pago mes a mes</b>
                      </div>
                      <div class="py-1">
                        <b>{{ plans.dia_pago }}</b>
                      </div>
                    </div>
                    <div class="col-12 text-center py-2">
                      <div class="py-1">
                        <b>Valor de la cuota inicial</b>
                      </div>
                      <div class="py-1">
                        <b>{{ plans.cuota_inicial  | currency }}</b>
                      </div>
                    </div>
                    <div class="col-12 text-center py-2">
                      <div class="py-1">
                        <b>Monto financiado</b>
                      </div>
                      <div class="py-1">
                        <b>{{ plans.monto_total  | currency}}</b>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>

import AppSubHeader from "@/components/layout/AppSubHeader.vue";
import { mapState } from "vuex";

export default {
  name: 'Plan',
  components: {
    AppSubHeader
  },
  computed: {
    ...mapState({ plans: state => state.plans.plan }),
  },

}

</script>